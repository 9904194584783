const errors = {
    required: "Texts:Required field",
    toShort: "Texts:At least 3 characters",
    exactlyFourDigits: "Texts:Should be exactly 4 digits",
    weakPassword: "Texts:At least one digit and title letter",
    invalidEmail: "Texts:Not a valid email",
    invalidPhone: "Texts:Not a valid phone",
    onlyChars: "Texts:Only letters are allowed",
    onlyDigits: "Texts:Only digits are allowed",
    phoneIsTooShort: "Texts:Phone number is too short",
    passwordsNotTheSame: "Texts:Passwords should be the same",
}

function required(v: any) {
    if (!v) return errors.required;
    if (Array.isArray(v) && v.length == 0) return errors.required;
    if (typeof v == "object" && Object.keys(v).length == 0) return errors.required;

    return true;
}

function minlength(v: any, n: number = 3) {
    return !!v && v.length >= n || errors.toShort;
}

function pincode(v: any) {
    return !!v && v.length == 4 || errors.exactlyFourDigits;
}

function password(v: any) {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/g.test(v) || errors.weakPassword;
}

function email(v: any) {
    if (!v) return true;

    return !!v && /^[^@]+@[^\s@]+$/g.test(v) || errors.invalidEmail;
}

function emailOrPhone(v: any) {
    if (!v) return true;

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v)) {
        if (!/^[^@]+@[^\s@]+$/g.test(v))
            return errors.invalidEmail;
    }

    //  if (/\+{0,1}[\d]+/g.test(v)) {
    //     if (!/^\+{0,1}[\d]+$/g.test(v))
    //         return errors.invalidPhone;        
    // }

    return true;
}

function onlyChars(v: any) {
    !!v && v.length > 0 && /^[a-zа-яєїі\s]+$/i.test(v) || errors.onlyChars
};

function onlyDigits(v: any) {
    if (!v) return true;
    return !!v && /^[\d]+$/i.test(v) || errors.onlyDigits;
}

function phone(v: any) {
    if (!v) return true;
    return !!v && v.length > 6 || errors.phoneIsTooShort;
}

function passwordRepeat(passFirst: string, passSecond: string) {
    if (passFirst == passSecond) return true;
    return errors.passwordsNotTheSame;
}

export default {
    required,
    minlength,
    pincode,
    password,
    passwordRepeat,
    phone,
    email,
    emailOrPhone,
    onlyChars,
    onlyDigits,
}