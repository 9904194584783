<script setup>
import { useValidation } from "@/composables/useValidation/useValidation";
import { useCountries } from "@/composables/useCountries";
import { transl } from "~/composables/useTranslation";

import rules from "@/utils/rules";

const props = defineProps([
  "modelValue",
  "defaultCountryIso",
  "rules",
  "label",
  "error",
  "readonly",
]);
const emit = defineEmits(["update:modelValue", "change", "enter", "blur"]);

const { findCountryByCode, findCountryByIso } = useCountries();

const form = inject("form", null);
const countriesDialog = ref(false);
const number = ref("");
const country = ref({});
const phoneHtmlEl = ref(null);

const rulesColl = [...(props.rules || []), rules.onlyDigits];

const validationModel = useValidation(number, rulesColl);
const { hasErrors, errors, validate } = validationModel;

function setValue(modelValue) {
  number.value = modelValue?.number;
  country.value =
    modelValue?.country ||
    findCountryByCode(modelValue?.code) ||
    findCountryByIso(props.defaultCountryIso);
}

function updateValue() {
  const phoneValue = {
    number: number.value,
    country: country.value,
    code: country.value?.code,
  };

  emit("update:modelValue", phoneValue);
  emit("change", phoneValue);
}

function handleEnter() {
  emit("update:modelValue", { ...modelValue });
  emit("enter");
}

function handleBlur() {
  validate();
  emit("blur");
}

function openDialog() {
  phoneHtmlEl.value.focus();
  countriesDialog.value = true;
}

function watchProps() {
  // outer props
  watch(
    () => props.modelValue,
    () => setValue(props.modelValue),
    { deep: true, immediate: true }
  );

  // inner props
  watch(
    () => [number.value, country.value, props.defaultCountryIso],
    () => updateValue(),
    { deep: true, immediate: true }
  );
}

onMounted(() => {
  if (form) form.register(validationModel);
  watchProps();
});

onUnmounted(() => {
  if (form) form.unregister(validationModel);
});
</script>

<template>
  <div
    class="v-phone-field"
    tabindex="0"
    :class="{ error: hasErrors }"
    :ref="(el) => (phoneHtmlEl = el)"
  >
    <v-label :class="{ error: hasErrors, active: true }" class="label">
      {{ label }}</v-label
    >

    <v-code-button
      v-model:country="country"
      :readonly="props.readonly"
      class="pa-0 code-el"
      @click="openDialog()"
    />
    <div class="dash-el">-</div>
    <v-countries-dialog
      v-model:dialog="countriesDialog"
      v-model:country="country"
      class="countries-dialog-el"
    />

    <v-text-field
      v-model="number"
      :readonly="props.readonly"
      class="number-el"
      placeholder="000000000"
      @blur="handleBlur()"
      @enter="handleEnter()"
      hideDetails
      flat
    >
    </v-text-field>
    <v-error :class="{ active: hasErrors }" class="error-el">{{
      transl(errors[0])
    }}</v-error>
    <slot name="append"></slot>
  </div>
</template>
<style>


</style>

<style scoped>


.v-phone-field {
  position: relative;
  display: flex;
  align-items: center;

  height: 50px;

  color: var(--text-color, #000);
  background-color: var(--text-form-input-background);
  outline: 1px solid var(--forms-border, #b3b3b3);

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -khtml-border-radius: 5px;

  transition: border-color 0.15s ease;
  padding: 2px 20px;
}


.v-phone-field:not(.error):focus-within {
  outline: 2px solid var(--forms-border-focus, #0957d0);
}

.v-phone-field.error {
  border: 1px solid rgb(255, 104, 28) !important;
}

.v-phone-field .v-error {
  top: 14px !important;
}

.number-el {
  font-size: 26px;
  font-weight: 100;
  height: calc(100% - 10px);
  width: 100%;
  border-radius: 5px;
  padding-left: 10px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.error-el {
  margin-left: 20px;
}

.code-el {
  display: flex;
  justify-content: flex-end;
}

.dash-el {
  width: 10px;
  display: flex;
  justify-content: center;
}

* {
  box-sizing: border-box;
}
</style>
~/composables/useTranslation
